import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';

import EventCard from '../Common/EventCard';

const StyledDiv = styled.div`
  .react-tabs__tab-list {
    margin: 0px;
    border-bottom: 1px solid #b02929;
  }
  .react-tabs__tab {
    font-weight: bold;
    background: #403E42;
    color: #fff;
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 8px;
    margin-right: 2px;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #b02929;
  }
  .react-tabs__tab--selected,
  .react-tabs__tab:hover {
    background: #b02929;
    color: #fff;
  }
  .react-tabs__tab-panel {
    padding: 24px 0;
    background: transparent;
  }
`

const KeyDatesSectionNewVersion = () => {
  const data = useStaticQuery(graphql`
    query KeyDatesSectionNewVersionQuery {
      prismicKeyDates {
        data {
          title
          section_title
          section_description {
            richText
          }          
        }
      }
      allPrismicKeyDatesSection(sort: { fields: data___year, order: DESC }) {
        edges {
          node {
            data {
              year
              section_title
              key_dates_list {
                event_day
                event_month
                event_title
                date_range
                event_description
                all_day
              }
            }
          }
        }
      }
    }
  `);

  const doc = data.prismicKeyDates
  const dates = data.allPrismicKeyDatesSection.edges

  return (
    <>
      <div className="w-full max-w-screen-lg mt-6 mb-16">
        <h2 className='text-2xl md:text-3xl 2xl:text-4xl text-gray-900 font-bold'>{doc.data.section_title}</h2>
        {doc.data.section_description.richText
          ?
          <div className="mt-6">
            <RichText
              render={doc.data.section_description.richText}
              htmlSerializer={htmlSerializer}
            />
          </div>
          :
          null
        }
      </div>

      <StyledDiv>
        <Tabs>
          <TabList>
            {dates.map(item => {
              return (
                <Tab>{item.node.data.section_title}</Tab>
              )
            })}
          </TabList>

          {dates.map(item => {
            const year = item.node.data.year
            return (
              <TabPanel>
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6">
                {item.node.data.key_dates_list.map(item => {
                    return (
                      item.event_description && item.all_day
                      ?
                      <EventCard
                        eventDay={item.event_day}
                        eventMonth={item.event_month}
                        eventTitle={item.event_title}
                        dateRange={item.date_range}
                        eventText={item.event_description}
                        allDay
                        eventYear={year}
                      />
                      :
                      item.event_description
                      ?
                      <EventCard
                        eventDay={item.event_day}
                        eventMonth={item.event_month}
                        eventTitle={item.event_title}
                        dateRange={item.date_rang}
                        eventText={item.event_description}
                        eventYear={year}
                      />
                      :
                      item.all_day
                      ?
                      <EventCard
                        eventDay={item.event_day}
                        eventMonth={item.event_month}
                        eventTitle={item.event_title}
                        dateRange={item.date_range}
                        allDay
                        eventYear={year}
                      />
                      :
                      <EventCard
                        eventDay={item.event_day}
                        eventMonth={item.event_month}
                        eventTitle={item.event_title}
                        dateRange={item.date_range}
                        eventYear={year}
                      />
                    )
                  })}
                </div>
              </TabPanel>
            )
          })}             
        </Tabs>
      </StyledDiv>
    </>
  )
};

export default KeyDatesSectionNewVersion
